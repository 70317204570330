import React from "react";
import Tag from "../Tag";
import * as Animate from "react-reveal";
import { Link } from "gatsby";
import Img from "../../assets/images/bg-shapes.png";
import BannerImg from "../../assets/images/ambassador/ambassador.webp";

export default function Jumbotron() {
  return (
    <main className="h-screen pt-1 lg:pt-10 h-[80%] bg-cover bg-center">
      <div className="w-11/12 m-auto py-16 max-w-7xl">
        <div className="w-full flex flex-col gap-y-8 md:flex-row justify-between items-start">
          <div className="w-10/12 flex flex-col justify-center md:justify-start items-center md:items-start gap-y-4  md:w-[55%] xl:w-[57%] md:gap-y-8 pt-4 lg:pt-10 mx-auto md:mx-0 text-center md:text-left">
            <Animate.Fade bottom>
              <Tag text="Reeddi Capsule" />
              <h1 className="text-4xl font-semibold leading-tight md:text-4xl lg:text-4xl xl:text-5xl capitalize">
                Become a Reeddi ambassador. Be a part of the solution.
              </h1>
              <p className="mt-2 mb:mt-3 max-w-[500px] text-base lg:text-lg mb-4 lg:mb-6 text-cstm-grey">
                Our ambassadors are entrepreneurs, traders and SMEs operating
                within our target communities.
              </p>
              <Link
                target={"_blank"}
                rel="noopener noreferrer"
                to="http://wa.me/+2348121295930"
                className="text-xs font-medium py-4 px-6 lg:px-8 md:text-lg rounded-lg bg-cstm-green text-[#fff] border-2 border-cstm-green ease-in duration-300 hover:bg-transparent hover:text-cstm-green capitalize">
                let’s have a chat
              </Link>
            </Animate.Fade>
          </div>

          <div className="mt-6 md:mt-0 w-full md:w-[40%]">
            <Animate.Fade right>
              <img className="w-fit" src={BannerImg} alt="research" />
            </Animate.Fade>
          </div>
        </div>
      </div>
    </main>
  );
}
