import React from 'react';
import CriterionCard from './CriterionCard'
import * as Animate from "react-reveal"
import CheckIcon from "../../assets/images/ambassador/check.svg"

import CriteriaBgImg from "../../assets/images/ambassador/md-criteria.png"
import WomanImg from "../../assets/images/ambassador/woman.png"

const criteria = [
    {
        id: '1',
        icon: CheckIcon,
        title: 'Have a Storefront',
        subTitle: 'To become a Reeddi ambassador, you must prove that you own a store, shop, or stable location where you generate daily revenue from providing goods or services.'
    },

    {
        id: '2',
        icon: CheckIcon,
        title: 'Get verified',
        subTitle: "Complete our KYC form to verify your identity and confirm your store's eligibility."
    },

    {
        id: '3',
        icon: CheckIcon,
        title: 'Be enthusiastic',
        subTitle: 'You represent Reeddi in your community and help me meet the demands of our local customers. Do this with excitement and a good team spirit.'
    },
]

export default function Criteria() {
    return (
        <section className='main-abstract-bg'>
            <div className='w-11/12 mx-auto py-16 lg:py-24 max-w-7xl'>
                <h2 className='text-[#ffffff] font-bold  text-left text-3xl mb-4 md:text-4xl lg:text-5xl lg:leading-tight w-full md:w-5/12 xl:pr-8 capitalize'>
                    Criteria for becoming a Reeddi ambassador
                </h2>
                <div className='mt-4 h-[3px] bg-white w-full md:w-5/12 xl:pr-8' />

                <div className='mt-16 lg:mt-20'>
                    <Animate.Fade bottom>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                            {criteria.map((criterion) => {
                                return (
                                    <CriterionCard key={criterion.id} criterion={criterion} />
                                )
                            })}
                        </div>
                    </Animate.Fade>
                </div>
            </div>
        </section>
    );

}

