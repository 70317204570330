import React from "react";
import * as Animate from "react-reveal";

export default function CriterionCard({ criterion }) {
  const { icon, title, subTitle } = criterion;

  return (
    <div className="flex justify-start items-start gap-x-4 bg-white px-4 lg:px-6 pt-6 pb-8 lg:pb-10 rounded-lg">
      <Animate.Fade bottom>
        <div className="w-6 h-6 md:h-auto md:w-auto">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
              fill="#128324"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 18.75L15 23.25L25.5 12.75"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="flex flex-col gap-y-2 ">
          <h4 className="font-semibold text-base md:text-lg capitalize">
            {title}
          </h4>
          <p className="text-cstm-grey text-sm-15 md:text-sm-15 capitalize">
            {subTitle}
          </p>
        </div>
      </Animate.Fade>
    </div>
  );
}
