import React from 'react';


export default function StepCard({ step }) {
    const { icon, title, subTitle, line } = step

    return(
    <div className='flex justify-start items-start gap-x-4 z-10'>
        <img src={ icon } className="w-6 h-6 md:w-auto md:h-auto" alt='check mark'/>

        <div className='flex gap-x-4 lg:gap-x-8 lg:mt-3 w-full max-h-36'>
            {/* {line && <img className='mt-6 lg:-ml-9' src={Img} alt='line'/>} */}
            <div className='flex flex-col gap-y-2 bg-white rounded-lg px-4 py-6 w-full lg:w-10/12 md:-mt-8' style={{boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)"}}>
                <h4 className='font-bold text-base md:text-lg capitalize'>{ title }</h4>
                <p className='text-cstm-grey capitalize'>{ subTitle[0] }</p>
            </div>
        </div>
    </div>
    );
}
