import React from "react";
import { Link } from "gatsby";
import * as Animate from "react-reveal";
import ModelImg from "../../assets/images/ambassador/models.png";
import CTABg from "../../assets/images/ambassador/bg-cta.webp";

export default function Cta() {
  return (
    <section className="mt-14 lg:mt-20 mb-16 md:mb-24">
      <Animate.Fade bottom>
        <div
          className="w-11/12 mx-auto pt-16 pb-16 md:pt-28 md:pb-28 lg:pt-32 lg:pb-32  max-w-7xl rounded-xl"
          style={{
            backgroundImage: `url(${CTABg})`,
            backgroundPosition: "center",
            backgroundSize: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <div className="flex flex-col justify-center md:flex-row justify-around items-center pt-16 md:pt-4">
            <div className=" flex flex-col justify-center items-center py-4 md:gap-y-8">
              <Animate.Fade bottom>
                <div className="flex flex-col md:gap-y-4">
                  <h2 className="w-10/12 md:w-full mx-auto font-semibold mb-2 md:mb-0 text-[white] text-4xl text-center md:text-left md:text-4xl lg:text-5xl xl:text-5xl lg:leading-tight">
                    Become a Reeddi Ambassador
                  </h2>
                  <p className="mt-4 text-[#fff] mb-2 md:mb-0 text-base text-center  md:text-base lg:text-lg">
                    Sign up to be a Reeddi Ambassador
                  </p>
                </div>
                <div className="relative mt-8 lg:mt-10">
                  <Link
                    to="http://wa.me/+2348121295930"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="py-4 px-8 lg:px-12 bg-[#fff] mt-2  font-semibold text-sm md:text-base md:m-0 text-cstm-green rounded hover:shadow-lg">
                    Sign Up Here
                  </Link>
                </div>
              </Animate.Fade>
            </div>
          </div>
        </div>
      </Animate.Fade>
    </section>
  );
}
