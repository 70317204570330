import React from 'react'

const Card = ({ title, subtitle }) => {
    return <div className='px-6 lg:px-10 py-8 lg:py-12 rounded-xl become-ambassador-card'>
        <p className='pt-2 text-lg lg:text-xl font-semibold text-[#313131]'>{title}</p>
        <p className='mt-4 text-sm-15 lg:text-base lg:leading-7 capitalize text-[#5C5757]'>{subtitle}</p>
    </div>
}

const BecomeAnAmbassador = () => {
    return (
        <div className='container px-4 md:px-6 xl:px-0 mx-auto pt-14 lg:pt-20 pb-8 bg-white'>
            <h2 className='text-2xl md:text-3xl lg:text-4xl font-semibold text-center'>Benefits Of Becoming A Reeddi Ambassador</h2>
            <div className='mt-6 mb-8 w-full lg:w-11/12 mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8'>
                <Card title={"Extra Income"} subtitle="Diversify your source of income by being a Reeddi Ambassador" />
                <Card title={"Reliable Power"} subtitle="Have access to 24/7 power to run your business" />
                <Card title={"Community Development"} subtitle="Provide reliable energy to businesses in your community at an affordable price" />
            </div>
        </div>
    )
}

export default BecomeAnAmbassador