import React from "react";
import StepCard from "./StepCard";
import * as Animate from "react-reveal"
import StepsBg from "../../assets/images/ambassador/bg-steps.png"
import DotIcon from "../../assets/images/ambassador/dot.svg"
import LineImg from "../../assets/images/ambassador/dash.svg"

const steps = [
    {
        id: "1",
        icon: DotIcon,
        title: "😉 Get Started ",
        subTitle: [
            <p className='text-cstm-grey text-sm md:text-base'>By filling out an application form <a className="underline underline-offset-2" target="_blank" rel="noopener noreferrer" href="http://wa.me/+2349155558121">here</a>. We need to get basic information about you and your store.
            </p>
        ],
        line: true,
    },

    {
        id: "2",
        icon: DotIcon,
        title: "👋 Get Verified ",
        subTitle: [
            <p className='text-cstm-grey text-sm md:text-base'>Wait for your application to be verified. A representative from the Reeddi team will reach out on the status of your application.</p>
        ],
        line: true,
    },

    {
        id: "3",
        icon: DotIcon,
        title: "🚀 Become an Ambassador",
        subTitle: [
            <p className='text-cstm-grey text-sm md:text-base'>Voila! You are now a Reeddi Ambassador.</p>
        ],
        line: false,
    },
];

export default function Steps() {
    return (
        <section
            className="bg-white"
        >
            <div className="w-11/12 mx-auto py-16 max-w-7xl">
                <div className="flex flex-col gap-y-4 py-8">
                    <Animate.Fade bottom>
                        <span
                            className="text-sm w-fit rounded text-cstm-grey font-medium py-2 px-2"
                        >
                            3 simple steps&gt;&gt;&gt;&gt;
                        </span>
                        <div className="relative flex flex-col gap-y-16 sm:flex-row md:flex-row justify-between items-start">
                            <div className=" flex flex-col gap-y-4 sm:w-[50%] md:w-[45%] lg:w-[40%]">
                                <h2 className="font-semibold text-3xl leading-tight md:text-4xl xl:text-5xl xl:leading-tight">
                                    How to become a Reeddi Ambassador.
                                </h2>
                                <div className='mt-4 h-[3px] bg-cstm-green w-11/12 xl:pr-8' />
                            </div>

                            <div className="relative flex flex-col gap-y-14 sm:w-[45%] md:w-[50%] lg:max-w-[40%] steps-marker">
                                {steps.map((step) => {
                                    return <StepCard key={step.id} step={step} />;
                                })}
                                <div className="absolute top-0 left-4 z-0">
                                    <img className='' src={LineImg} alt='line' />
                                </div>
                            </div>
                        </div>
                    </Animate.Fade>
                </div>
            </div>
        </section>
    );
}
