import React from 'react';
import { Contact, Newsletter } from '../components';
import { Jumbotron, Criteria, Cta, Steps } from '../components/AmbassadorComponents'
import BecomeAnAmbassador from '../components/AmbassadorComponents/BecomeAnAmbassador';
import Layout from '../containers/Layout'

export default function Ambassador(){
  return(
      <Layout title={"Become An Ambassador"}>
        <Jumbotron/>
        <Criteria/>
        <Steps/>
        <BecomeAnAmbassador />
        <Cta/>
        <Contact />
      </Layout>
  );
}




